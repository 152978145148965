.swiper-wrapper {
  margin: auto;
  width: fit-content;
}
.swiper-slide {
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 10px;
}
