body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#root {
  height: 100vh;
  width: 100vw;
}
