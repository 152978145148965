.heading {
  margin-left: 50%;
}

.container {
  display: flex;
  justify-content: space-evenly;
}

.facets {
  width: 20%;
}

.facet {
  margin-top: 10px;
}

.facet h2 {
  font-weight: bold;
}

.search-results {
  width: 75%;
}

.hits {
  margin-top: 1rem;
}

.Pagination {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  margin: 0 auto;
}
